import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getFieldProperty(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_PROPERTY}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getFieldProperties(dataTableParams?: DataTableParamsModel) {
  let url;
  const baseUrl = `${c.API_ENDPOINTS.FIELD_PROPERTY}`;
  const fetchParams = dataTableParams?.fetchParams;

  url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;
  return (
    axios({
      url: url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getFieldPropertiesByEntry(entryId: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_PROPERTY_BY_ENTRY}/${entryId}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getFieldPropertiesByEntryByShortcodes(groupShortcode: string, clientShortcode: string, entryId: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_PROPERTY_BY_ENTRY}/${groupShortcode}/${clientShortcode}/${entryId}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postFieldProperty(groupId: string, clientId: string, fieldTypeId: string, fieldId: string, fieldTypeOptionId: string, value: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.FIELD_PROPERTY,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        fieldTypeId,
        fieldId,
        fieldTypeOptionId,
        value,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putFieldProperty(
  id: string,
  groupId: string,
  clientId: string,
  fieldTypeId: string,
  fieldId: string,
  fieldTypeOptionId: string,
  value: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_PROPERTY}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        fieldTypeId,
        fieldId,
        fieldTypeOptionId,
        value,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveFieldProperty(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_PROPERTY}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function unarchiveFieldProperty(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.FIELD_PROPERTY}/${id}/unarchive`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
